import React from "react";
import { Alert, Jumbotron } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { MdInfoOutline } from "react-icons/md";
import BrandColorsHorizontalDivider from "./BrandColorsHorizontalDivider";

export const getSpecificationDocument = (specificationDocumentWalkthroughs, type) =>
  specificationDocumentWalkthroughs.filter(
    (specificationDocumentWalkthrough) => specificationDocumentWalkthrough.type[0] === type
  )[0];

export const getBaseSpotlightRoute = (network) => `/network/${network.slug.current}/`;
export const getNavigationRoutes = (props, baseSpotlightRoute) => {
  return [
    {
      id: "be9d145e-326f-42f7-aa1c-c84e69620885",
      title: "Intro",
      route: `${baseSpotlightRoute}intro`,
      show: props.intro,
    },
    {
      id: "b7bbfa83-bab2-481d-8fbb-d817b3135d05",
      title: "Guide",
      route: `${baseSpotlightRoute}deep-dive`,
      show: props.guide,
      subroutes: [
        {
          id: "8ec6558a-724a-4d6a-83e2-174e58e94155",
          title: "Implications",
          hash: `#implications`,
          show: true,
        },
        {
          id: "d5271b25-7a4e-495d-9f8e-4b108c4566ae",
          title: "How it Works",
          hash: `#how-it-works`,
          show: true,
        },
        {
          id: "f002d5d2-b916-4800-9a8c-912ccd91a024",
          title: "Tokens",
          hash: `#tokens`,
          show: true,
        },
        {
          id: "ccf7b815-8c9a-49da-b079-e813de38cd54",
          title: "Network Roles",
          hash: `#network-roles`,
          show: true,
        },
        {
          id: "e715c456-f76f-4776-ad7a-5d519b497ae0",
          title: "Technologies/Concepts",
          hash: `#technologies-concepts`,
          show: true,
        },
      ],
    },
    {
      id: "65d23c20-8d48-437c-b0bf-cdd91a9dd93c",
      title: "Quiz",
      route: `${baseSpotlightRoute}quiz`,
      show: props.quiz,
    },
    {
      id: "22b694e9-74c4-40f3-916a-e3fae7b824f4",
      title: "Details",
      route: `${baseSpotlightRoute}details`,
      show: props.details,
    },
  ];
};

const SpotlightLayout2 = (props) => {
  const { children, resource } = props;

  const { spotlight, roles, systemComponents, specificationDocumentWalkthroughs } = resource;

  const { network, isCommissioned, _updatedAt, isInBeta } = spotlight;

  const baseSpotlightRoute = getBaseSpotlightRoute(network);

  const navigationRoutes = getNavigationRoutes(props, baseSpotlightRoute);

  return (
    <Container className="mb-5">
      <Row className="mb-3">
        <Col xl={{ span: 10, offset: 1 }}>
          {children}
          {isInBeta && (
            <div className="mb-3">
              <Alert variant="secondary">
                <div className="d-flex align-items-center">
                  <MdInfoOutline className="mr-1" size={30} />{" "}
                  <div>
                    This Spotlight is in beta. If you find something you think is wrong, help the
                    community and{" "}
                    <Alert.Link
                      className="text-decoration-none"
                      href="mailto:jay@rebase.foundation"
                    >
                      let us know
                    </Alert.Link>
                    .
                  </div>
                </div>
              </Alert>
            </div>
          )}
        </Col>
      </Row>
      {network.brandColors && (
        <BrandColorsHorizontalDivider brandColors={network.brandColors} marginBottomNumber={3} />
      )}
      {isCommissioned && (
        <>
          <Jumbotron className="text-center">
            <div className="mb-2 w-text mx-auto">
              This Spotlight was commissioned by the THORChain team to help spread knowledge and
              develop the community. Members of the Rebase team had holdings of RUNE exceeding $100
              in value throughout the project.
            </div>
          </Jumbotron>
          {network.brandColors && (
            <BrandColorsHorizontalDivider
              brandColors={network.brandColors}
              marginBottomNumber={5}
            />
          )}
        </>
      )}
      <Jumbotron fluid className="text-center" style={{ backgroundColor: "#f1f1f1" }}>
        <div className="overline mb-2">Created by</div>
        <a href="https://rebase.foundation">
          <img src={require("../images/Rebase-logo.svg")} style={{ width: 125 }} className="mb-2" />
        </a>
        <div className="w-50 mx-auto lead mb-1">Tools for thought, aimed at crypto investors</div>
        <a href="https://rebase.foundation">
          Visit main site <small>→</small>
        </a>
      </Jumbotron>
    </Container>
  );
};

export default SpotlightLayout2;
