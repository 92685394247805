import { graphql } from "gatsby";
import React from "react";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Spotlight from "../components/spotlight";
import SpotlightLayout2 from "../components/SpotlightLayout2";
import Layout from "../containers/layout";

export const query = graphql`
  query SpotlightTemplateQuery {
    systemComponents: allSanitySystemComponent(
      filter: { network: { title: { eq: "tbtc" } }, externalLink: { eq: null } }
    ) {
      nodes {
        id
        isReady
        title
        externalLink
        _rawDescription2(resolveReferences: { maxDepth: 5 })
        image {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }

    roles: allSanityRole(
      filter: { network: { title: { eq: "tbtc" } } }
      sort: { fields: isReady, order: DESC }
    ) {
      nodes {
        _key
        isReady
        title
        _rawSummary(resolveReferences: { maxDepth: 4 })
        avatar {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }

    spotlight: sanitySpotlight(id: { eq: "892fe638-c142-5bc5-aa56-3b0acd106ff4" }) {
      id
      isCommissioned
      isInBeta
      image {
        asset {
          fluid(maxWidth: 1500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawSocialImage
      network {
        title
        logo {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
        _rawLogoIcon
        emoji
        brandColors
        slug {
          current
        }
      }
      intro {
        summaryLine
        summaryImage {
          asset {
            fluid(maxWidth: 1250) {
              ...GatsbySanityImageFluid
            }
          }
        }
        backgroundImage {
          asset {
            fluid(maxWidth: 1250) {
              ...GatsbySanityImageFluid
            }
          }
        }
        implicationsImage {
          asset {
            fluid(maxWidth: 1250) {
              ...GatsbySanityImageFluid
            }
          }
        }
        visionImage {
          asset {
            fluid(maxWidth: 1250) {
              ...GatsbySanityImageFluid
            }
          }
        }
        _rawSimpleWhatItIs(resolveReferences: { maxDepth: 1 })
        _rawSimpleImplications(resolveReferences: { maxDepth: 1 })
        _rawSimpleBackground(resolveReferences: { maxDepth: 1 })
        _rawSimpleVision(resolveReferences: { maxDepth: 1 })
      }
      guide {
        _rawDeepImplications(resolveReferences: { maxDepth: 1 })
        technologies {
          title
          id
          abbreviation
        }
        concepts {
          id
          title
          explainerUrl
          description
          abbreviation
        }
        roles {
          _key
          isReady
          title
          _rawAvatar
          avatar {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          cost
          benefit
          behavior
        }
        tokensNew {
          _key
          token {
            title
            ticker
            type
            image {
              asset {
                fixed(width: 100) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
          function
        }
        systemComponents {
          _rawImage
          _rawDescription2(resolveReferences: { maxDepth: 5 })
          _rawNotes(resolveReferences: { maxDepth: 5 })
          title
          _key
        }
        systemOverview {
          _key
          id
          image {
            asset {
              fixed(width: 600) {
                ...GatsbySanityImageFixed
              }
            }
          }
          _rawSummary2(resolveReferences: { maxDepth: 10 })
          _rawDescription2(resolveReferences: { maxDepth: 5 })
          _rawNotes(resolveReferences: { maxDepth: 5 })
        }
      }
      specificationDocumentWalkthroughs {
        id
        title
        isReady
        image {
          asset {
            fluid(maxWidth: 200) {
              ...GatsbySanityImageFluid
            }
          }
        }
        type
        _rawText(resolveReferences: { maxDepth: 2 })
        _rawQAndA(resolveReferences: { maxDepth: 2 })
        audioID
        VideoID
      }
      details {
        sector {
          title
          id
        }
        industry {
          title
          id
        }
        people {
          id
          _rawImage
          name
        }
      }
      _updatedAt(formatString: "DD MMM YYYY")
    }
  }
`;

const SpotlightTemplate = (props) => {
  const { data, errors, pageContext } = props;
  const { spotlightLayoutResource } = pageContext;
  const spotlight = data && data.spotlight;
  const systemComponents = data && data.systemComponents.nodes;
  const roles = data && data.roles.nodes;

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {spotlight && (
        <SEO
          title={`${spotlight.network.title} Intro, Guide and Whitepaper Walkthrough`}
          description={`Learn why ${spotlight.network.title} is relevant, how it works and how you can get involved. All with super simple explanations!`}
          image={spotlight._rawSocialImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {spotlight && (
        <SpotlightLayout2 resource={spotlightLayoutResource}>
          <Spotlight {...spotlight} roles={roles} systemComponents={systemComponents} {...props} />
        </SpotlightLayout2>
      )}
    </Layout>
  );
};

export default SpotlightTemplate;
