import { Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Jumbotron } from "react-bootstrap";
import Hero from "./Hero";

const Home = (props) => {
  const {
    image,
    network,
    _updatedAt,
    specificationDocumentWalkthroughs,
    roles,
    systemComponents,
    intro,
  } = props;

  return (
    <>
      <Link to="/intro">
        <Hero
          image={image}
          superheading="Spotlight"
          heading={network.title}
          subheading={`${intro.summaryLine}`}
          subheadingSize="lead text-white"
          height="70vh"
          className="mb-3"
        />
      </Link>
      <Jumbotron className="pt-3">
        <div className="overline mb-2">Intro</div>
        <h1 className="mb-1">Get the gist</h1>
        <div className="lead w-text mb-3">
          What tBTC is, where it fits into crypto and how it works at a high-level
        </div>
        <Link to="/intro" className="text-decoration-none text-muted">
          View intro <small>→</small>
        </Link>
      </Jumbotron>
      <a
        href="https://rebase.foundation/post/btc-is-arriving-on-ethereum-what-are-the-implications-of-tbtc"
        className="text-decoration-none text-muted"
      >
        <Img fluid={intro.implicationsImage.asset.fluid} className="img-fluid" />
      </a>
      <Jumbotron className="pt-3">
        <div className="overline mb-2">What's the big deal</div>
        <h1 className="mb-1">tBTC's coming to Ethereum – why does that matter?</h1>
        <div className="lead w-text mb-3">
          We spent 6 weeks learning about tBTC – here's how we think it will change the crypto world
        </div>
        <a
          href="https://rebase.foundation/post/btc-is-arriving-on-ethereum-what-are-the-implications-of-tbtc"
          className="text-decoration-none text-muted"
        >
          Read post <small>→</small>
        </a>
      </Jumbotron>
      <Link to="/deep-dive" className="text-decoration-none text-muted">
        <img src={require("../images/tBTC –guide .jpg")} className="img-fluid" />
      </Link>
      <Jumbotron className="pt-3">
        <div className="overline mb-2">Guide</div>
        <h1 className="mb-1">Dive Deeper</h1>
        <div className="lead w-text mb-3">
          Which roles can participate in the network? What are the main processes? Which tokens are
          involved?
        </div>
        <Link to="/deep-dive" className="text-decoration-none text-muted">
          Check out Guide <small>→</small>
        </Link>
      </Jumbotron>
      <Link to="/whitepaper" className="text-decoration-none text-muted">
        <img src={require("../images/whitepaper walkthrough.jpg")} className="img-fluid" />
      </Link>
      <Jumbotron className="pt-3">
        <div className="overline mb-2">Get close to the metal</div>
        <h1 className="mb-1">The Whitepaper, Made Simple</h1>
        <div className="lead w-text mb-3">
          We simplified the whitepaper in video, audio and text formats.
        </div>
        <Link to="/whitepaper" className="text-decoration-none text-muted">
          Read Walkthrough <small>→</small>
        </Link>
      </Jumbotron>
      <Link to="/intro" className="text-decoration-none">
        <Jumbotron className="text-center text-decoration-none border">
          <div className="overline mb-2">Get started</div>
          <div className="display-2 mb-2">Intro</div>
          <div className="">→</div>
        </Jumbotron>
      </Link>
    </>
  );
};

export default Home;
