import React, { Component } from "react";
import DeepDive from "./DeepDive";
import Guide from "./Guide";
import Home from "./Home";

class Spotlight extends Component {
  render() {
    const { network, specificationDocumentWalkthroughs, path, intro } = this.props;

    const networkSlugCurrent = network.slug.current;

    console.dir(this.props);

    return (
      <>
        {(path === `/` || path === "") && <Home {...this.props} />}
        {path === `/intro` && <Guide {...this.props} />}
        {path === `/deep-dive` && <DeepDive {...this.props} />}
      </>
    );
  }
}

export default Spotlight;
