import { Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Jumbotron } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import { FaExternalLinkAlt } from "react-icons/fa";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";
import RoleInterestEmailForm from "./RoleInterestEmailForm";

const DeepDive = (props) => {
  const { guide, intro, network, details } = props;

  const fungibleTokens = guide.tokensNew.filter((tokenNode) => tokenNode.token.type === "Fungible");
  const nonFungibleTokens = guide.tokensNew.filter(
    (tokenNode) => tokenNode.token.type === "Non-Fungible"
  );

  return (
    <>
      <h1 className="mb-3">Guide</h1>
      <div className="mb-4">
        <Row>
          {details.sector && (
            <Col>
              <h6 className="mb-0 overline">Sector</h6>
              <h4 className="mb-0">{details.sector.title}</h4>
            </Col>
          )}
          {details.industry && (
            <Col>
              <h6 className="mb-0 overline">Industry</h6>
              <h4 className="mb-0">{details.industry.title}</h4>
            </Col>
          )}
        </Row>
      </div>
      <h3 className="mb-2" id="implications">
        Implications
      </h3>
      <Row className="mb-5 d-flex align-items-center">
        <Col xl={7}>
          <Img fluid={intro.implicationsImage.asset.fluid} className="img-fluid mb-1" />
        </Col>
        <Col xl={5}>
          {guide._rawDeepImplications && <PortableText blocks={guide._rawDeepImplications} />}
        </Col>
      </Row>
      {((guide.systemComponents && guide.systemComponents.length > 0) || guide.systemOverview) && (
        <div className="mb-5">
          <h3 className="mb-2" id="how-it-works">
            How it Works
          </h3>
          <div className="mb-5">
            <h4>Overview</h4>
            {guide.systemOverview && (
              <>
                <Row className="d-flex align-items-center">
                  <Col md={6}>
                    {guide.systemOverview._rawSummary && (
                      <PortableText blocks={guide.systemOverview._rawSummary} />
                    )}
                    {guide.systemOverview._rawDescription2 && (
                      <PortableText blocks={guide.systemOverview._rawDescription2} />
                    )}
                    {guide.systemOverview._rawNotes && (
                      <>
                        <div className="overline mb-1">Notes</div>
                        <PortableText blocks={guide.systemOverview._rawNotes} />
                      </>
                    )}
                  </Col>
                  <Col md={6}>
                    {guide.systemOverview.image && (
                      <Img
                        fixed={guide.systemOverview.image.asset.fixed}
                        className="img-fluid mb-1"
                      />
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>
          <h4>Components</h4>
          {guide.systemComponents.length > 0 &&
            guide.systemComponents.map((systemComponent) => (
              <div key={systemComponent._key} className="mb-3">
                <h5 className="mb-1">{systemComponent.title}</h5>
                <Row>
                  <Col md={systemComponent._rawImage ? 6 : 12}>
                    {systemComponent._rawDescription2 && (
                      <div className="w-text">
                        <PortableText blocks={systemComponent._rawDescription2} />
                      </div>
                    )}
                  </Col>
                  {systemComponent._rawImage && (
                    <Col md={6}>
                      <img
                        src={imageUrlFor(buildImageObj(systemComponent._rawImage))
                          .width(1000)
                          .auto("format")
                          .url()}
                        className="mb-1 img-fluid"
                      />
                    </Col>
                  )}
                </Row>
                {systemComponent._rawNotes && (
                  <>
                    <div className="overline mb-1">Notes</div>
                    <div className="w-text">
                      <PortableText blocks={systemComponent._rawNotes} />
                    </div>
                  </>
                )}
              </div>
            ))}
        </div>
      )}
      {(fungibleTokens.length > 0 || nonFungibleTokens.length > 0) && (
        <div className="mb-5">
          <h3 className="mb-2" id="tokens">
            Tokens
          </h3>
          {fungibleTokens.length > 0 && (
            <div className="mb-3">
              <h5 className="mb-1">Fungible</h5>
              {fungibleTokens.map((tokenNode) => (
                <>
                  <div className="mb-1 overline">
                    {tokenNode.token.title}{" "}
                    {tokenNode.token.ticker &&
                      tokenNode.token.ticker !== tokenNode.token.title &&
                      `(${tokenNode.token.ticker})`}
                  </div>
                  <Row className="d-flex align-items-center mb-3">
                    <Col md={8} className="mb-2 mb-sm-0">
                      {tokenNode.function && <div className="w-text">{tokenNode.function}</div>}
                    </Col>
                    <Col md={4}>
                      {tokenNode.token.image && (
                        <Img fixed={tokenNode.token.image.asset.fixed} className="img-fluid" />
                      )}
                    </Col>
                  </Row>
                </>
              ))}
            </div>
          )}
          {nonFungibleTokens && nonFungibleTokens.length > 0 && (
            <div>
              <h5 className="mb-1">Non-Fungible</h5>
              {nonFungibleTokens &&
                nonFungibleTokens.map((tokenNode) => (
                  <div key={tokenNode._key} className="mb-3">
                    <div className="mb-1 overline">{tokenNode.token.title}</div>
                    {tokenNode.function && <div className="w-text">{tokenNode.function}</div>}
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
      {guide.roles && guide.roles.length > 0 && (
        <div className="mb-5">
          <h3 className="mb-2" id="network-roles">
            Network Roles
          </h3>
          <Row>
            {guide.roles.map((role) => (
              <Col md={12} className="mb-4">
                <Card>
                  <Card.Body>
                    <Row className="mb-3">
                      <Col xl={8}>
                        <div className="d-flex align-items-center mb-2">
                          <h5 className="mr-2 mb-0">{role.title}</h5>
                          {/* <RoleInterestButton title={role.title} network={network} /> */}
                        </div>
                        <div className="mb-0 w-text">
                          {role.behavior && role.behavior} {role.benefit && role.benefit}{" "}
                          {role.cost && role.cost}
                        </div>
                      </Col>
                      <Col xl={4}>
                        <div className="mb-1 d-flex justify-content-between">
                          {role._rawAvatar && (
                            <img
                              src={imageUrlFor(buildImageObj(role._rawAvatar))
                                .width(400)
                                .auto("format")
                                .url()}
                              style={{ width: 200 }}
                              className="ml-auto"
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                    <RoleInterestEmailForm {...props} />
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      )}
      {(guide.concepts.length > 0 || guide.technologies.length > 0) && (
        <>
          <h3 className="mb-2" className="mb-2" id="technologies-concepts">
            Technologies/Concepts
          </h3>
          <Card className="mb-3">
            <ListGroup variant="flush">
              {guide.technologies.map((technology) => (
                <ListGroup.Item key={technology.id}>
                  <div>
                    {technology.title} {technology.abbreviation && `(${technology.abbreviation})`}
                  </div>
                </ListGroup.Item>
              ))}
              {guide.concepts.map((concept) => (
                <ListGroup.Item key={concept.id}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      {concept.title}
                      {concept.abbreviation && ` (${concept.abbreviation})`}
                    </div>
                    {concept.explainerUrl && (
                      <a href={concept.explainerUrl}>
                        More info <FaExternalLinkAlt />
                      </a>
                    )}
                  </div>
                  {concept.description && <div>{concept.description} </div>}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card>
        </>
      )}
      <Link to="/whitepaper" className="text-decoration-none">
        <Jumbotron className="text-center text-decoration-none border">
          <div className="overline mb-2">Next</div>
          <div className="display-2 mb-2">Whitepaper Walkthrough</div>
          <div className="">→</div>
        </Jumbotron>
      </Link>
    </>
  );
};

export default DeepDive;
