import { Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Col, Jumbotron, Row } from "react-bootstrap";
import PortableText from "./portableText";

const Section = (props) => <section className="mb-5">{props.children}</section>;

const Guide = (props) => {
  const { guide, intro, network } = props;

  return (
    <>
      <h1 className="mb-3">Intro</h1>
      <Section>
        <Row className="d-flex align-items-center">
          <Col md={12}>
            <Img
              fluid={intro.summaryImage.asset.fluid}
              className="img-fluid my-3"
              alt={`${network.title} cryptonetwork summary`}
            />
          </Col>
          <Col md={8}>
            <div className="w-text lead">
              {intro._rawSimpleWhatItIs && <PortableText blocks={intro._rawSimpleWhatItIs} />}
            </div>
          </Col>
        </Row>
      </Section>
      <Section>
        <Row className="d-flex align-items-center">
          <Col md={12}>
            <Img
              fluid={intro.implicationsImage.asset.fluid}
              className="img-fluid my-3"
              alt={`Implications of the ${network.title} cryptonetwork`}
            />
          </Col>
          <Col md={8}>
            <div className="w-text lead">
              {intro._rawSimpleImplications && (
                <PortableText blocks={intro._rawSimpleImplications} />
              )}

              <a href="https://rebase.foundation/post/btc-is-arriving-on-ethereum-what-are-the-implications-of-tbtc">
                Learn more about the implications of tBTC
              </a>
            </div>
          </Col>
        </Row>
      </Section>
      <Section>
        <Row className="d-flex align-items-center">
          <Col md={12}>
            <Img
              fluid={intro.backgroundImage.asset.fluid}
              className="img-fluid my-3"
              alt={`Background and motivation of the ${network.title} cryptonetwork`}
            />
          </Col>
          <Col xl={8}>
            <div className="w-text lead">
              {intro._rawSimpleBackground && <PortableText blocks={intro._rawSimpleBackground} />}
            </div>
          </Col>
        </Row>
      </Section>
      <Section>
        <Row className="d-flex align-items-center">
          <Col md={12}>
            <Img
              fluid={intro.visionImage.asset.fluid}
              className="img-fluid my-3"
              alt={`Vision of the ${network.title} cryptonetwork`}
            />
          </Col>
          <Col md={8}>
            <div className="w-text lead">
              {intro._rawSimpleVision && <PortableText blocks={intro._rawSimpleVision} />}
            </div>
          </Col>
        </Row>
      </Section>
      <Section>
        <Row className="d-flex align-items-center">
          <Col md={12}>
            <Img
              fixed={guide.systemOverview.image.asset.fixed}
              className="img-fluid my-3"
              alt={`System overview of the ${network.title} cryptonetwork`}
            />
          </Col>
          <Col md={8}>
            {guide.systemOverview && guide.systemOverview._rawSummary2 && (
              <div className="w-text lead">
                <PortableText blocks={guide.systemOverview._rawSummary2} />
                <Link to={`/deep-dive#how-it-works`}>
                  Learn more about how {network.title} works
                </Link>
              </div>
            )}
          </Col>
        </Row>
      </Section>
      <Link to="/deep-dive" className="text-decoration-none">
        <Jumbotron className="text-center text-decoration-none border">
          <div className="overline mb-2">Next</div>
          <div className="display-2 mb-2">Guide</div>
          <div className="">→</div>
        </Jumbotron>
      </Link>
    </>
  );
};

export default Guide;
