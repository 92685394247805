import addToMailchimp from "gatsby-plugin-mailchimp";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const RoleInterestEmailForm = props => {
  const [email, setEmail] = useState("");
  const [result, setResult] = useState(null);

  const _handleSubmit = async e => {
    e.preventDefault();
    setResult(await addToMailchimp(email));
  };
  return result ? (
    <>
      {result.result === "success" && (
        <div className="text-success mb-1">👍 We'll email you updates about this role</div>
      )}
      {result.result === "error" && (
        <div className="text-danger mb-1">
          😢Something went wrong – <a href="hello@rebase.foundation">email us</a> and we'll fix it.
        </div>
      )}
    </>
  ) : (
    <Form onSubmit={_handleSubmit} className="d-sm-block d-none">
      <Form.Group>
        <Form.Label>Get updates about this role</Form.Label>
        <div style={{ clear: "both" }} />
        <div className="d-flex align-items-center">
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            style={{ width: "250px" }}
            className="mr-1"
          />
          <Button variant="outline-primary" type="submit">
            Send
          </Button>
        </div>
      </Form.Group>
    </Form>
  );
};

export default RoleInterestEmailForm;
